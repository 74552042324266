import React, { useCallback, useEffect, useRef } from "react"
import { Link, navigate } from "gatsby"

import { Helmet } from "react-helmet"
import * as css from "./ProfileSettings.module.css"
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap"
import * as globalCss from "../../components/styles/global.module.css"

import log from "../../components/log"

import useAuth from "../../hooks/useAuth"
import { LoadingPageOnly } from "../../components/auth/LoadingPage"
import useAxiosPrivate from "../../hooks/useAxiosPrivate"
import LoadingKtp from "../../components/form-create-id/LoadingKtp"
import ReactTooltip from "react-tooltip"
import ReactTimeAgo from "react-time-ago"
import Moment from "react-moment"
import { toast } from "react-toastify"

const ProfileSettings = ({ }: any) => {
    /**
     * Constant
     */
    const apiEndpoint = process.env.APP_API_ENDPOINT
    const isDebug = (process.env.APP_DEBUG == 'true')
    const { isAuthenticated, isLoading, accessToken } = useAuth()
    const axiosPrivate = useAxiosPrivate()

    /**
     * Declare state
     */
    const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] = React.useState(false)
    const [isUserConfirmDeletion, setIsUserConfirmDeletion] = React.useState(false)
    const [isUserDeletionSavingLoading, setIsUserDeletionSavingLoading] = React.useState(false)
    const [isInDeletionSchedule, setIsInDeletionSchedule] = React.useState(false)


    const [isUserUndoDeletionSavingLoading, setIsUserUndoDeletionSavingLoading] = React.useState(false)


    const [isAccountSettingsLoading, setIsAccountSettingsLoading] = React.useState(true)
    const [accountSettings, setAccountSettings] = React.useState({
        termination_at: null,
    })

    /**
     * Call the API
     */
    React.useEffect(() => {
        setIsAccountSettingsLoading(true)
        let endpoint = "/api/account/settings"
        log('debug', 'Calling AccountSettings API', {
            endpoint: endpoint,
        })

        const getInfo = async () => {
            try {
                const res = await axiosPrivate.get(endpoint)

                if (res.status === 200) {
                    setAccountSettings(res.data.data)

                    if (
                        (
                            res.data.data.termination_at == undefined ||
                            res.data.data.termination_at == null
                        ) == false
                    ) {
                        setIsInDeletionSchedule(true)
                    }

                    setIsAccountSettingsLoading(true)
                }
            } catch (err: any) {
                if (err?.response == undefined) {
                    log("error", "Gagal terhubung ke server, silahkan muat ulang halaman.", {
                        file: 'ProfileSettings',
                        func: 'getInfo',
                        exception: err,
                    })

                    return false;
                }

                // Ignore token expired error
                if (err?.response?.status != 401) {
                    log("error", "Gagal saat mengambil data dari server, silahkan muat ulang halaman. Jika masih mengalami kendala silahkan login ulang.", {
                        endpoint: endpoint,
                        error: err,
                    })
                }
            }
        }
        getInfo()
    }, [isAuthenticated, accessToken])

    /**
     * Calling Schedule Deletion API
     */
    function doDeletion() {
        const post = async () => {
            // Call API
            setIsUserDeletionSavingLoading(true)
            let endpoint = '/api/account/delete_account'
            if (isDebug) {
                log("debug", "Calling API.", {
                    endpoint: endpoint,
                })
            }

            try {
                const res = await axiosPrivate.post(endpoint, {})

                if (res.status === 200) {
                    toast.success(res.data.message)
                    setIsDeleteAccountModalVisible(false)
                    setIsInDeletionSchedule(true)
                }
            } catch (err: any) {
                if (err?.response == undefined) {
                    log("error", 'Tidak dapat menghubungi server, coba lagi.', {
                        endpoint: endpoint,
                        error: err,
                    })
                } else {
                    log("error", err?.response?.data?.message, {
                        endpoint: endpoint,
                        error: err,
                    })
                }
            }

            setIsUserDeletionSavingLoading(false);
        }
        post();
    }

    /**
     * Calling Undo Deletion API
     */
    function doUndoDeletion() {
        const post = async () => {
            // Call API
            setIsUserUndoDeletionSavingLoading(true)
            let endpoint = '/api/account/undo_delete_account'
            if (isDebug) {
                log("debug", "Calling API.", {
                    endpoint: endpoint,
                })
            }

            try {
                const res = await axiosPrivate.post(endpoint, {})

                if (res.status === 200) {
                    toast.success(res.data.message)
                    setIsInDeletionSchedule(false)
                }
            } catch (err: any) {
                if (err?.response == undefined) {
                    log("error", 'Tidak dapat menghubungi server, coba lagi.', {
                        endpoint: endpoint,
                        error: err,
                    })
                } else {
                    log("error", err?.response?.data?.message, {
                        endpoint: endpoint,
                        error: err,
                    })
                }
            }

            setIsUserUndoDeletionSavingLoading(false);
        }
        post();
    }


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pengaturan Akun</title>
            </Helmet>

            {isLoading && (
                <>
                    <LoadingPageOnly />
                </>
            )}
            {isAuthenticated && (
                <>
                    <Container className={css.section}>
                        <Row>
                            <Col md="6">
                                <h1>Pengaturan Akun</h1>
                            </Col>
                            <Col md="6" className={css.textRight}></Col>
                        </Row>

                        <Row>
                            <Col>
                                {!isAccountSettingsLoading ? (
                                    <>
                                        <LoadingPageOnly />
                                    </>
                                ) : (
                                    <div className={css.container}>
                                        {
                                            isInDeletionSchedule ? (
                                                <>
                                                    {
                                                        isUserUndoDeletionSavingLoading ? (
                                                            <Button
                                                                disabled={true}
                                                            >
                                                                <div className={globalCss.dotLoading}> Memproses</div>
                                                            </Button>
                                                        ) : (
                                                            <>
                                                                <Button onClick={() => { doUndoDeletion() }}>
                                                                    Batal Hapus Akun
                                                                </Button>
                                                                {
                                                                    accountSettings.termination_at != null ? (
                                                                        <div className={css.smallText}>

                                                                            Dijadwalkan dihapus <ReactTimeAgo future date={new Date(accountSettings.termination_at * 1000)} />
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            </>
                                                        )
                                                    }

                                                </>

                                            ) : (
                                                <Button variant="danger" onClick={() => { setIsDeleteAccountModalVisible(true) }}>
                                                    Hapus Akun
                                                </Button>
                                            )
                                        }
                                    </div>
                                )}
                            </Col>
                        </Row>

                    </Container>

                    {/* Schedule delete account modal */}
                    <Modal show={isDeleteAccountModalVisible} onHide={() => { setIsDeleteAccountModalVisible(false) }} className={css.remindMeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Hapus Akun</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Setelah menekan tombol konfirmasi, akun kamu akan direncanakan dihapus dalam 7 hari.{' '}
                            Setelah terhapus, data benar-benar hilang dan tidak dapat dikembalikan.
                            <br />
                            <br />
                            Sebelum waktu tunggu habis kamu bisa membatalkan proses penghapusan.
                            <br /><br />
                            <Form.Check
                                onClick={() => { setIsUserConfirmDeletion(!isUserConfirmDeletion) }}
                                type={'checkbox'}
                                label={'Saya mengkonfirmasi penghapusan data.'}
                                checked={isUserConfirmDeletion}
                            />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => { setIsDeleteAccountModalVisible(false) }}>
                                Batal
                            </Button>
                            {
                                isUserDeletionSavingLoading ? (
                                    <Button
                                        disabled={true}
                                    >
                                        <div className={globalCss.dotLoading}> Memproses</div>
                                    </Button>

                                ) : (
                                    <Button
                                        variant="danger"
                                        disabled={!isUserConfirmDeletion}
                                        onClick={() => { doDeletion() }}
                                    >
                                        Hapus Akun
                                    </Button>

                                )
                            }

                        </Modal.Footer>
                    </Modal>
                </>
            )
            }
        </>
    )
}

export default ProfileSettings
